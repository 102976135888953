
@mixin shine {
    mask-image: linear-gradient(-75deg, rgba(0,0,0,.9) 30%, #000 50%, rgba(0,0,0,.6) 70%);
    animation: shine 800ms infinite ease-in-out;   
    @keyframes shine {
        from {
          -webkit-mask-position: 150%;
        }
      
        to {
          -webkit-mask-position: -50%;
        }
    }
}