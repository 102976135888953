/**
*   offer.scss
*   @description: Estilos para la página de ofertas
*/

.offer  {

    .left-column {
        width: 65%;
        padding: 16px;    
        background: white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    }

    .right-column {
        width: 33%;
        padding: 16px;
        background: white;
        align-self: flex-start;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    }
    
    .icon-btn{
        padding-right: $paddingNormal;
    }

    h2:empty, .date:empty, .name:empty, .responsive-img-logo:empty, .badges-all:empty, .description:empty {
        min-width: 100%;
        mask-size: 200%;
        @include shine;
        background-color: $lightGray;
    }

    .responsive-img-logo:empty { width: 60px; height: 60px; }
    h2:empty { height: 36px; }
    .date:empty, .name:empty { height: 22px; }
    .badges-all:empty { height: 32px; }
    .description:empty { height: 120px; }

    .quick-info {
        img { max-width: 85px; }
        .padding-left { padding-left: $paddingNormal; }
        .column {
            justify-content: center;
        }
    }

    .date-fav {
        position: relative;
    }
    .btn-fav {
        top: 0;
        right: 0.9rem;
        position: absolute;
        background-color: transparent;
        i { font-size: 1rem; }
    }

    .line-divider {
        height: 1px;
        background-color: $lightGray;
    }

    .videos {
        .thumbnail {
            width: 50%;
            position: relative;
            margin: 0 $paddingNormal;
            background-color: $black;
            border-radius: 4px 0 0 4px;
            &:first-child { margin-left: 0; }
            &:last-child { margin-right: 0; }
            video {
                width: 100%;
                min-height: 200px;
            }
            img { opacity: 0.8; } 
            .btn-play {
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                background: transparent;
            }
        }
    }

}

.offer-add {
    .row-tags {
        input { width: 75%; }
        button { width: 25%; padding: 6px }
    }
    
}


@media screen and (max-width: 768px) {

    .offer {

        .view-web { display: none; }
        h2:empty, .date:empty, .name:empty, .responsive-img-logo:empty, .badges-all:empty, .description:empty {
            min-width: 100%;
            mask-size: 200%;
            @include shine;
            background-color: $lightGray;
        }

        .responsive-img-logo:empty { width: 60px; height: 60px; }
        h2:empty { height: 36px; }
        .date:empty, .name:empty { height: 22px; }
        .badges-all:empty { height: 32px; }
        .description:empty { height: 120px; }

        .quick-info {
            img { max-width: 60px; }
            .padding-left { padding-left: $paddingNormal; }
            .column {
                justify-content: center;
            }
        }

        .date-fav {
            position: relative;
        }
        .btn-fav {
            top: 0;
            right: 0.9rem;
            position: absolute;
            background-color: transparent;
            i { font-size: 1rem; }
        }

        .line-divider {
            height: 1px;
            background-color: $lightGray;
        }

        .videos {
            .thumbnail {
                width: 50%;
                position: relative;
                margin: 0 $paddingNormal;
                background-color: $black;
                border-radius: 4px 0 0 4px;
                &:first-child { margin-left: 0; }
                &:last-child { margin-right: 0; }
                img { opacity: 0.8; } 
                .btn-play {
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                }
            }
        }

    }

    .offer-add {
        .row-tags {
            input { width: 75%; }
            button { width: 25%; }
        }
        
    }

}