
.left-bar {
    top: 0;
    width: 100px;
    height: 100vh;
    position: sticky;
    background-color: $darkBlue;
    box-shadow: -3px -2px 12px 0px black;

    .item-left { 
        height: 13%;
        a {
            margin: auto;
        }
    }

    .responsive-img {
        height: 16%;
    }

    .image-profile {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 2px solid $lightBlue;
        object-fit: cover;
        object-position: center;
    }

    .main { padding: $paddingNormal; }

    .item-bar, .item-bar h5 {
        transition: color 300ms ease-in-out;
        color: $white;

        .icon-menu {  font-size: 1.5rem;  }

        &:hover, &:hover > h5 { color: $lightBlue; }
    }

    .active-menu, .active-menu h5, .active-menu > h5 { color: $lightBlue; }       

}