/**
*   profile-public.scss
*   @description: Estilos para la vista publica de perfil
*/

.profile-candidate {

    .list-videos {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 16px;
    }

    .list-cards {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 16px;
        
    }
    .container-profile {
        width: 85%;
    }

    .conteiner-labels {

        background: $white;
        padding: 8px 16px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);

        .row-labels {
            justify-content: start;
        }

        .user-label {
            background: $lightBlue;
            padding: 2px 8px 6px 8px;
            border-radius: 5px;
            margin-right: 5px;
            margin-bottom: 5px;

            .h5 { font-size: $fontLarge; }
        }
    }

    
    .header-profile {
        height: auto;
        background: white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);

        .icon-config {
            cursor: pointer;
            font-size: $fontLarge;
            color: $darkBlue;
            position: absolute;
            right: 60px;
            top: 16px;

            &:hover{
                color: $lightBlue;
            }
        }

        .icon-header{
            margin-right: 16px;
        }

        .icon-ubication {
            margin-left: 3px;
        }

        .text-abilities {
            margin-right: 16px;
        }
        

        .content-btns {
            padding: $paddingMedium;
            width: 50%;
        }

        .column-info {
            position: relative;
        }

        .icon-btn {
            margin-right: 8px;
        }
       
    }

    .profile-column{
        width: 60%;
        .profile-image {
            width: 230px;
            height: 210px;
            margin: 16px 40px 16px 16px;
            border: 2px solid #FFFFFF;
            object-fit: cover;
            object-position: center center;
            -webkit-transition: width .5s;
             transition: width  .5s;
        }
    }

    .gallery-profile {
        max-height: 450px;
        overflow: auto;
    }

    .my-offers #os {
        max-height: 540px;
        overflow: auto;
    }

    .offers #os {
        max-height: 450px;
        overflow: auto;
    }
}

@media screen and (max-width: 992px) {
 .profile-candidate {

    .list-videos {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 16px;
    }

    .header-profile {
        height: auto;
        background: white;

        .icon-header{
            margin-right: 16px;
        }

        .text-abilities {
            margin-right: 16px;
        }
       

        .content-btns{
            padding: $paddingMedium;
            width: 60%;

            h5{
                font-size: .7rem;
            }
        }

        .column-info{
            position: relative;
        }

        
       
    }


    .profile-column{
        width: 75%;
        
        .profile-image {
            width: 160px;
            height: 160px;
            margin: 0px 8px; 
            border: 2px solid #FFFFFF;
            object-fit: cover;
            object-position: center center;
        }
    }

    
    .column-info {        
        .white-space-24{
            height: 16px;
        }

        .white-space-16{
            height: 8px;
        }

        p {
            font-size: 1rem;
        }
    }
}
}

@media screen and (max-width: 768px) {
   
    .profile-candidate {
        background-color: $white;
        min-height: 100vh;
      
        
        .name:empty, .age:empty, .city:empty {
            background-color: $lightGray;
            min-width: 100%;
            mask-size: 200%;
            @include shine;
        }

        .name:empty { min-height: 26px; }

        .age:empty, .city:empty { min-height: 20px; }

        .view-web { display: none; }

        .column-info {
            width: 50%;
        }

        .candidate-cards {
            margin: 8px 0;
            border-radius: 4px;
            background-color: $bgGray;
            box-shadow: 0 4px 2px rgba(0, 0, 0, 0.16);
            .title {
                top: 0;
                height: 100%;
                position: absolute;
            }

            .bottom {
                bottom: $paddingMedium;
                position: absolute;
            }

            .tabs-container {
                margin-bottom: 8px;
            }

            .tabs-container .column {
                width: 46%;
                &:first-child { margin-right: 4px; }
                &:last-child { margin-left: 4px; }
            }

            .tabs-container .btn { 
                width: 100%;
                background-color: $bgGray;
                border: 1px solid $lightGray;
            }

            .tabs-container .active-tab { background-color: $lightBlue; }

            .sector {
                h5 {
                    width: 92%;
                    font-size: 0.7rem;
                    margin-right: 4px;
                    background-color: $darkBlue;
                    padding: 4px $paddingNormal;
                }
            }

        }

        .conteiner-labels {
            padding: 4px 0;
            border-bottom: 1px solid $lightGray;
            .soft-skills {
                .flickity-button { background: transparent; }
                .flickity-button-icon { fill: transparent; } 
                .flickity-button:disabled { display: none; }
            }
            .row-labels{
                justify-content: center;
            }

            .user-label {
            
                background: $lightBlue;
                padding: 2px 8px 6px 8px;
                border-radius: 15px;
                margin-right: 5px;
                margin-bottom: 5px;
                .h5 { font-size: .6rem; }
            }
        }

        .profile-container {
            position: relative;
            width: 50%;
            padding-top: 5px;
    
            .image-profile {
                width: 110px;
                height: 110px;
                border-radius: 50%;
                margin: 2px 0px 2px 0px;
                border: 5px solid $bgGray;
                object-fit: cover;
                object-position: center center;
            }

            
    
            h5 { font-size:  $fontMini; }
        
        }

        .data-profile {
            padding-right: 10px;
        }

        .gallery {
            .photo {
                width: 48%;
                margin: 4px 0px 4px 0px;            
                position: relative;
                cursor: pointer;
            }

            .img-gallery {
                width: 100%;
                height: 100px;
                object-fit: cover;
                border-radius: 10px;
            }
            .tittle {
                position: absolute;
                height: 100%;
                background: #1313136b;
                border-radius: 10px;
                
            }
        }

        .fixed-contact {
            position: fixed;
            bottom: 0;
            button {
                width: 50%;
                padding: 12px $paddingNormal;
            }
        }

        .vc {
        
            height: 300px;
            overflow: auto;            
            padding: 1px;

            .card-video {
                width: 100%;
                min-height: 175px;
                position: relative;
                .edit {
                    border-radius: 0;
                }

                .contact {
                    position: absolute;
                    bottom: 0;
                    width: 50%;
                }
                
            }
        }


        .my-offers #os {
            height: 290px;
            overflow: auto;
        }

        .offers #os {
            height: 320px;
            overflow: auto;
        }

        .btn-container-candidate {
            a { width: 100%; }
        }


        .profile-private {

            .card-video {
                position: relative;
                .contact {
                    position: absolute;
                    margin-top: 10px;
                    bottom: 0;
                    width: 25%;
                    right: 0;
                }
    
                .edit {
                    position: absolute;
                    bottom: 0;
                    width: 25%;
                }
            }
        }
    }

    

}