/**
*   cards.scss
*   @description: Estilos para todas las tarjetas
*/

.card-video {
    width: 100%;
    height: 143px;
    border-radius: 4px;
    h4 { font-size: $fontSmall };
    margin: 0;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);

    .card-name {
        height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .thumbnail {
        border-radius: 4px 0 0 4px;
        width: 50%;
        position: relative;
        background-color: $black;
        height: 100%;

        video {
            width: 100%;
            height: 100%;
        }

        img { opacity: 0.8; }

        .btn-play {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            background-color: transparent;
        }
    }
    video {
        width: 100%;
        height: auto;
    }
    .info {
        width: 60%;
        padding: 10px 0 0 0;
        .padding { padding-left: 10px; }
    }

    .contact {
        margin-top: 16px;
        position: relative;
        border-radius: 0 0 4px 0;
        padding: $paddingNormal 0;
        background-color: $lightBlue;
        button { background-color: transparent; }
    }

    .delete {
        margin-top: 16px;
        position: relative;
        border-radius: 0 0 4px 0;
        padding: $paddingNormal 0;
        background-color: $darkBlue;
        button { background-color: transparent; }
    }
    .edit {
        margin-top: 16px;
        position: relative;
        border-radius: 0 0 4px 0;
        padding: $paddingNormal 0;
        background-color: $lightBlue;
        button { background-color: transparent; }
    }
    .sector {
        font-size: 0.8rem;
        line-height: 16px;
        padding-bottom: 4px;
    }

    .contact-delete button, .contact-delete {
        background-color: $darkBlue;
    }

}

.card-job, .card-company {
        width: 100%;
        height: auto;
        border-radius: 4px;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
        .card-name {
            height: 22px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .head {
            .logo { 
                position: relative; 
                border-radius: 4px 0 0 4px;
            }
            width: 40%;
            img { 
                top: 0;
                width: 100%;
                height: 100%;
                max-height: 100%;
                object-fit: cover;
                position: absolute;
                object-position: center;
                border-radius: 4px 0 0 4px;
            }
        }
        .info {
            width: 60%;
            padding: 10px 0 0 0;
            h4 { font-size: $fontSmall; }
            .title p { margin-bottom: 4px; }
            .padding { padding-left: 10px; }
        }
        .contact {
            margin-top: 4px;
            border-radius: 0 0 4px 0;
            padding: $paddingNormal 0;
            background-color: $lightBlue;
            button { background-color: transparent; }
        }
    }

    .sector {
        font-size: 0.8rem;
        line-height: 16px;
        padding: 0 2px 4px 0 ;
    }

    .card-company {
        .logo {
            img {
                width: 100%;
            }
        }
    }

    .card-candidate {
        .card-name {
            height: 22px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .head {
            img { 
                width: 100%;
                height: 100%;
                min-width: 100%;
            }
        }
        .btn {
            width: 100%;
            border-radius: 0 0 4px 0;
            margin-top: 1px;
        }
    }

.card-my-offers {
    .card-name {
        height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .info {
        width: 65%;
    }
    .quick {
        width: 35%;
    }
    #remove-candidatos {
        background-color: $darkBlue;   
    }
}
.card-company {
    min-height: 130px;
}
.card-company-fav, .card-video-fav, .card-job-fav {
    .card-name {
        height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .btn-container {
        width: 100%;
    }
    .contact { 
        width: 50%; 
        margin-top: 16px;
        border-radius: 0;
        background-color: $lightBlue;
        button { 
            background-color: transparent;
        }
    }
    .contact-remove { 
        width: 50%;
        margin-top: 16px;
        border-radius: 0 0 4px 0;
        padding: $paddingNormal 0; 
        background-color: $darkBlue;
        button { background-color: transparent; }
    }
    .sector {
        height: 36px;
        font-size: 0.8rem;
        line-height: 16px;
        padding: 0 2px 4px 0 ;
    }
}

.card-suscripcion {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);

    .import { margin-left: 16px; }

    #check-content { padding: 0 8px; }

    #btn-option {
        width: 100%;
        padding: 10px 16px;
        cursor: pointer;
    }
}


.card-my-offers {
    .card-name {
        height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .info {
        width: 50%;
    }
    .quick {
        width: 50%;
    }
    #remove-candidatos {
        background-color: $darkBlue;   
    }
    
    .btn {
        width: 50%;
        &:first-child { border-radius: 0 0 0 4px; }
        &:last-child { border-radius: 0 0 4px 0; }
    }
}

@media screen and (max-width: 992px) {
    .card-video {
        height: 170px;
    }
    
    .card-job, .card-company {
        height: 170px;
    }
}

@media screen and (max-width: 768px) {

    .card-video {
        width: 100%;
        min-height: 160px;
        border-radius: 4px;
        h4 { font-size: $fontSmall };
        margin: $marginNormal 0;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);

        .thumbnail {
            border-radius: 4px 0 0 4px;
            width: 50%;
            position: relative;
            background-color: $black;

            img { opacity: 0.8; }
            
            video {
                width: 100%;
                height: auto;
            }

            .btn-play {
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                background-color: transparent;
            }
        }
        .info {
            width: 50%;
            padding: 10px 0 0 0;
            .padding { padding-left: 10px; }
        }
        
        .contact {
            margin-top: 28px;
            position: relative;
            border-radius: 0 0 4px 0;
            padding: $paddingNormal 0;
            background-color: $lightBlue;
            button { background-color: transparent; }
        }
        .contact-delete {
            background-color: $darkBlue;
        }
    
        .edit {
            margin-top: 10px;
            position: relative;
            border-radius: 0 0 4px 0;
            padding: $paddingNormal 0;
            background-color: $lightBlue;
            button { background-color: transparent; }
        }
    }

    .card-job {
        min-height: 144px;
        height: auto;
    }

    .card-company {
        min-height: 135px;
    }

    .card-job, .card-company {
        width: 100%;
        height: auto;
        border-radius: 4px;
        margin: $marginNormal 0;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
        .head {
            .logo { 
                position: relative; 
                border-radius: 4px 0 0 4px;
            }
            width: 40%;
            img { 
                top: 0;
                height: 100%;
                max-height: 100%;
                object-fit: cover;
                position: absolute;
                object-position: center;
                border-radius: 4px 0 0 4px;
            }
        }
        .info {
            width: 60%;
            padding: 10px 0 0 0;
            h4 { font-size: $fontSmall; }
            .title p { margin-bottom: 4px; }
            .padding { padding-left: 10px; }
        }
        .contact {
            margin-top: 4px;
            border-radius: 0 0 4px 0;
            padding: $paddingNormal 0;
            background-color: $lightBlue;
            button { background-color: transparent; }
        }
    }

    .card-company {
        .logo {
            img {
                width: 100%;
            }
        }
    }

    .card-candidate {
        .head {
            img { 
                width: 100%;
                height: 100%;
                min-width: 100%;
            }
        }
        .btn {
            width: 100%;
            border-radius: 0 0 4px 0;
            margin-top: 1px;
        }
    }

    .card-my-offers {
        .info {
            width: 50%;
        }
        .quick {
            width: 50%;
        }
        #remove-candidatos {
            background-color: $darkBlue;   
        }
        
        .btn {
            width: 50%;
            &:first-child { border-radius: 0 0 0 4px; }
            &:last-child { border-radius: 0 0 4px 0; }
        }
    }

    .card-suscripcion {
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);

        .import { margin-left: 16px; }

        #check-content { padding: 0 8px; }

        #btn-option {
            width: 100%;
            padding: 10px 16px;
            cursor: pointer;
        }
    }

    /* --- Favorites cards --- */

    .card-video-fav {
        min-height: 132px;

        .btn-container {
            width: 100%;
            min-height: 32px;
        }
        .contact { 
            width: 50%; 
            margin-top: 4px;
            border-radius: 0;
            background-color: $lightBlue;
            button { 
                background-color: transparent;
            }
        }
        .contact-remove { 
            width: 50%;
            margin-top: 4px;
            border-radius: 0 0 4px 0;
            padding: $paddingNormal 0; 
            background-color: $darkBlue;
            button { background-color: transparent; }
        }
        .sector {
            font-size: 0.8rem;
            line-height: 16px;
            padding: 0 2px 4px 0 ;
        }
    }

    .card-company-fav, .card-job-fav {
        min-height: 144px;
        height: 144px;
        .btn-container {
            width: 100%;
            min-height: 32px;
        }
        .contact { 
            width: 50%; 
            margin-top: 4px;
            border-radius: 0;
            background-color: $lightBlue;
            button { 
                background-color: transparent;
            }
        }
        .contact-remove { 
            width: 50%;
            margin-top: 4px;
            border-radius: 0 0 4px 0;
            padding: $paddingNormal 0; 
            background-color: $darkBlue;
            button { background-color: transparent; }
        }
        .sector {
            font-size: 0.8rem;
            line-height: 16px;
            padding: 0 2px 4px 0 ;
        }
    }

}