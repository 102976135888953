/**
*   register.scss
*   @description: Estilos de la página de registro
*/

.login {
    .input-container {
        background-color: #EFEFEF;
        border: 1px solid transparent;
        transition: border 400ms ease-in-out;
        i {  
            color: #777777;
            padding-left: $paddingMedium;
        }
        &:focus-within { border: 1px solid $darkBlue; }
    }
    
    .btn {
        width: 100%;
        padding: $paddingMedium;
    }

    #btn-marcas { 
        width: 100%; 
        padding: $paddingNormal $paddingMedium;
    }

    #btn-facebook {
        border: 1px solid transparent;
        background-color: $darkBlue;
    }
}

@media screen and (max-width: 768px) {

    .login {
        h2 { font-size: $fontMedium; }
        #h2-register-brand { font-size: $fontRegular; }
        .input-container {
            .input { min-width: 85%; }
            &:hover { border: 1px solid $darkBlue; }
        }
    }

}