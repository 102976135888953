.video-page {

    .video-list {
        background: $white;
        width: 60%;
        padding: 0 16px;
        
        .card-video {
            border-radius: 4px;
            width: 100%;
            margin-bottom: 10px;

            video {
                width: 100%;
                height: 100%;
                max-height: 100px;
            }

            .contact {
                margin-top: 4px;
                background-color: $lightBlue;
                button {                     
                    padding: $paddingNormal;
                    background-color: transparent; 
                }
            }
        }
    }

    .video-desk{
        padding: 0 16px 0 0;
    }

    .video-full{
        width: 100%;
    }
    .video {
        .thumbnail {
            width: 100%;
            position: relative;
            background-color: $black;
            img { opacity: 0.8; }
            .btn-play {
                top: 50%;
                width: 100%;
                transform: translateY(-50%);
                position: absolute;
            }
        }
    }
    .details {
        padding: 10px 0;
        background-color: $white;
    }
    .fav-icon {
        padding-right: 10px; 
        button { background-color: transparent; }
    }
}


@media screen and (max-width: 768px) {
   
    .video-page {

        .view-web { display: none }
        
        .video {
            .thumbnail {
                width: 100%;
                position: relative;
                background-color: $black;
                img { opacity: 0.8; }
                .btn-play {
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                }
            }
        }
        .details {
            padding: 10px 0;
            background-color: $white;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
            .padding-left { padding-left: 10px; }
        }
        .fav-icon {
            padding-right: 10px; 
            button { background-color: transparent; }
        }
    }

}