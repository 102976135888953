

.profile-candidate {

    .list-videos {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 16px;
    }       

    .btn-container-public{
        width: 45%;
        margin-right: 8px;
    }

    .header-profile {
        height: auto;
        background: white;

        
        .public-profile-column{
            width: 60%;
        }
      

       
    }

    .profile-column-public{
        width: 50%;
        
        .profile-image-public{
            width: 230px;
            height: 210px;
            margin: 16px 40px 16px 54px;
            border: 2px solid #FFFFFF;
            object-fit: cover;
            object-position: center center;
            -webkit-transition: width .5s;
             transition: width  .5s;
        }
    }
}

@media screen and (max-width: 992px) {
 .profile-candidate {


    .header-profile {
        height: auto;
        background: white;

        .public-profile-column{
            width: 60%;
        }

        .content-btns{
            padding: $paddingMedium;
            width: 60%;

            h5{
                font-size: .7rem;
            }
        }

        .column-info{
            position: relative;
        }

        
       
    }


   

    .profile-column-public{
        width: 50%;
                
        .profile-image-public{
            width: 160px;
            height: 160px;
            margin: 16px 25px;
            border: 2px solid #FFFFFF;
            object-fit: cover;
            object-position: center center;
        }
        
    }
   
}
}

@media screen and (max-width: 768px) {
    .profile {
        min-height: 100vh;
        background-color: $bgGray;
        padding-bottom: $paddingLarge;

        .vc {
			height: 300px;
			overflow: auto;
		}

        .view-web { display: none; }

        
        .profile-container {
            position: relative;
            background:  linear-gradient(to bottom, $darkBlue 0%, $darkBlue 50%, $bgGray 50%, $bgGray 100%);
            padding-top: 15px;
            .image-profile {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                margin: 10px 0px 10px 0px;
                border: 1px solid $black;
                object-fit: cover;
                object-position: center center;
            }
    
            h5 { font-size:  $fontMini; }

            .i-left {
                position: absolute;
                left: 5%;
                font-size: $fontMedium;
            }
        }
    
        .basic-data {
            background: $white;
            border: 1px solid $gray;
            border-radius: 6px;
            padding: 5px;
        } 

        .i-right {
            position: absolute;
            right: 5%;
            top: 16px;
            font-size: $fontRegular;
        }

    }
    
   
}