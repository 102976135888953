/**
*   progress.scss
*   @description: Estilos de progressbar
*/


.progress-bar {
	width: 100%;
	background-color: $darkGray;
	.taskbar {
		font-family: $OpenSans;
		width: 1%;
		height: 30px;
		background-color: $lightBlue;
		text-align: center;
		justify-content: center;
		line-height: 30px;
		color: $darkBlue;
	}
}