.settings {
    background-color: $bgGray;

    .container-main-left {
        width: 50%;
    }
    
    .input-container {
        position: relative;
        .select-options {
            -webkit-appearance: none;
        }
        .icon-options {
            position: absolute;
            top: 5px;
            right: 32px;
        }

    }

    .right-main{
        background: $white;
        border: 1px solid rgb(177, 177, 177);
        border-radius: 5px;
        .content {
            position: relative;
        }
        .image-profile-edit {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            margin: 2px 0px 2px 0px;
            border: 2px solid #FFFFFF;
            object-fit: cover;
            object-position: center center;
        }

        .overlay-image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border: 2px solid #FFFFFF;
            margin: 2px 0px 2px 0px;
            opacity: 0;
            border-radius: 50%;
            transition: .5s ease;
            background-color: $lightBlue;
          }

          .content:hover .overlay-image{
            opacity: 1;
          }
    }

    .content-forms {
        padding: $paddingMedium;

        .tittle{
            margin-left: 32px;
        }

        .icon {
            font-size: $fontRegular;
        }
    }

    .left-main {
        width: 85%;
        background: white;
        border: 1px solid rgb(177, 177, 177);
        border-radius: 5px;

        .link{
            width: 100%;
        }

        .tittle-column { 
            width: 100%;
        }
        
        .icons-column {
            width: 14%;
        }    

        .element-main-active {
            padding: 16px 25px;
            background: lightgray;
            border-bottom: 1px solid $lightGray;
            cursor: pointer;

            .text-tittle {
                color: $darkBlue;
            }
        }
    
        .element-main {
            padding: 16px 25px;
            background: $white;
            cursor: pointer;
            border-bottom: 1px solid $lightGray;
          
            &:hover{
                background: lightgray;
                .icon-main, .tittle-main{
                    -webkit-transition: color .3s;
                    transition: color  .3s;
                    color: $lightBlue;
                }
            }
        } 
    }
}


@media screen and (max-width: 768px) {
    
    .settings {
        min-height: 100vh;
        padding-bottom: $paddingLarge;

        .view-web { display: none; }

        .container-main-left {
            width: 100%;
            margin-bottom: 16px;
        }

        .left-main {
            width: 100%;
        }
     
        .basic-data {
            background: $white;
            border: 1px solid $gray;
            border-radius: 6px;
            padding: 6px 4px;
        }

        .profile-container {
            position: relative;
            padding-top: 15px;
            background-color: $bgGray;
        }

        .i-left {
            position: absolute;
            left: 5%;
            font-size: $fontMedium;
            top: 16px;
        }
        .i-left-center {
            top: 20px;
        }
        .i-right {
            position: absolute;
            right: 5%;
            top: 16px;
            font-size: $fontRegular;
        }
        .i-right-center {
            top: 21px;
        }
        
        .blocked-container {
            height: 600px;
            overflow-y: scroll;

            .blocked-list {
                min-height: 90px;
                h4 { font-size: 1em; }
                h5 { font-size: .8em; }
                .blocked-column { width: 35%; }
                .image-cover {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin: 10px 10px 10px 0px;
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }

        .password {
            min-height: 90vh;
            input { width: 100%; }
        }
    }

    .profile-settings {
        background-color: $bgGray;
    }

}