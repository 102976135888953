.my-offers {
    .head-info { 
        padding: $paddingMedium 0;
        background-color: $white; 
    }

    .contact-remove {
        margin-top: 10px;
        padding: $paddingNormal 0;
        background-color: rgba($red, 0.5);
    }

    .list-videos {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 16px;
    }
}

@media screen and (max-width: 768px) {

    .my-offers {
        .head-info { 
            padding: $paddingMedium 0;
            background-color: $white; 
        }

        .contact-remove {
            margin-top: 10px;
            padding: $paddingNormal 0;
            background-color: rgba($red, 0.5);
        }
    }

}