.subscription{

    .list-subscription {
        background-color: $white;
        table, th, td {
            font-family: $OpenSans;
            border: 1px solid $lightGray;
            border-collapse: collapse;
            font-size: .95rem;
        }
        th, td {
            text-align: center;
            padding: 8px 0;  
        }
        th {
            width: 50%;
            background: $lightBlue;
            color: $darkBlue;
            padding: 10px 0;
        }
    }

}