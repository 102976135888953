/**
*   modals.scss
*   @description: Estilos de los Modales
*/


/**
*   Generales
*       modal-login
*       #modal-promotion
*       #modal-alert
*/


/**     ---     ESTILOS BASE PARA MODALES       ---   **/

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    width: 50%;
    border-radius: 4px;
    background-color: $white;
}
   

/**     ---     ESTILOS BASE PARA MODALES       ---   **/

#modal-delePublication .modal-content, #modal-deleInfluencerPublication .modal-content, #modal-delete-chat .modal-content, #modal-delete-account .modal-content {
    background-color: $white;
    border-radius: 4px;
    z-index: 2;
    width: 30%;

#btn-deletePublication {
    width: 50%;
    padding: $paddingMedium;
    border-radius: 0 0 0 4px;
    background-color: $darkBlue;
    border: 0;
}
#btn-deletePublicationCancel {
    width: 50%;
    padding: $paddingMedium;
    border-radius: 0 0 4px 0;
    border: 0;
}
}


#modal-delate-chat .modal-content, #modal-delete-chat .modal-content {
    background-color: $white;
    width: 30%;
    z-index: 2;
    margin: auto;
    position: relative;
    align-self: flex-start;

    #btn-deleteChat { 
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0 0 0 4px;
    }

    #close-modal-delete {
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0 0 4px 0;
    }
}

#modal-delete-account .modal-content {
    background-color: $white;
    width: 30%;
    margin: auto;
    position: relative;
    align-self: flex-start;

    #btn-deleteChat { 
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0 0 0 4px;
    }

    #close-modal-delete-account {
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0 0 4px 0;
    }
}
#modal-contact {
    .modal-content {
        width: 50%;
        p {
            width: 90%;
            text-align: left;
        }
        input, textarea {
            width: 90%;
        }
        button {
            width: 90%;
        }
    }
}

#modal-login .modal-content  {
    width: 45%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

    .text-regis{
        margin-left: 8px;
    }

    p, form {
        width: 60%;
    }     

    #close-modal-login {
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $darkBlue;
        &:hover {
            color : $lightBlue;
        }
        i {
            font-size: $fontLarge;
        }
    }

    #close-modal{
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $darkBlue;
        &:hover {
            color : $lightBlue;
        }
        i {
            font-size: $fontLarge;
        }
    }

    .logo-modal{
        position: absolute;
        left: 26px;
        top: 21px;
        width: 24%;
    }

    .icon-facebook{
        margin-right: 8px;
    }

}

#modal-register {
    .modal-content {
        width: 50%;
        margin: auto;
        position: relative;
        align-self: flex-start;
    }
    #close-modal-register {
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $darkBlue;
        &:hover {
            color : $lightBlue;
        }
        i {
            font-size: $fontLarge;
        }
    }
}

#modal-offer .modal-content {
    width: 40%;
    position: relative;

    #close-modal {
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $darkBlue;
        &:hover {
            color : $lightBlue;
        }
        i {
            font-size: $fontLarge;
        }
    }

    .input-offer {
        padding: 4px 0;
        border-bottom: 1px solid $lightGray;
    }

    #btn-top {
        border-radius: 0 0 4px 4px;
    }
}

#modal-edit-offer .modal-content {
    width: 40%;
    position: relative;

    #close-modal {
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $darkBlue;
        &:hover {
            color : $lightBlue;
        }
        i {
            font-size: $fontLarge;
        }
    }

    .input-offer {
        padding: 4px 0;
        border-bottom: 1px solid $lightGray;
    }

    #btn-top {
        border-radius: 0 0 4px 4px;
    }
}


#modal-video .modal-content {
    width: 35%;
    position: relative;

    #close-modal {
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $darkBlue;
        &:hover {
            color : $lightBlue;
        }
        i {
            font-size: $fontLarge;
        }
        
    }

    #btn-bottom {
        border-radius: 0 0 4px 4px;
        position: relative;
    }
}

#modal-videoCurriculum .modal-content {

    width: 35%;
    position: relative;

    #close-modal {
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $darkBlue;
        &:hover {
            color : $lightBlue;
        }
        i {
            font-size: $fontLarge;
        }
        
    }

    #btn-bottom {
        border-radius: 0 0 4px 4px;
        position: relative;
    }

}


#modal-Curriculum .modal-content {
    width: 35%;
    position: relative;
    background: $white;

    #close-modal {
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $darkBlue;
        &:hover {
            color : $lightBlue;
        }
        i {
            font-size: $fontLarge;
        }
        
    }

    #btn-bottom {
        border-radius: 0 0 4px 4px;
        position: relative;
    }
}

#modal-contact .modal-content  {
    width: 60%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

    .image-profile-modal {
        max-height: 340px;
    }   

    #close-modal-contact {
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $pink;
        &:hover {
            color : $purple;
        }
        i {
            font-size: $fontLarge;
        }
        
    }
}

#modal-delate .modal-content  {
    width: 30%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

    .image-profile-modal {
        max-height: 340px;
    }   

    .btn-confirm{
        margin-right: 8px;
    }
}

#modal-activation .modal-content  {
    width: 30%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

}

#modal-Add .modal-content  {
    width: 40%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

    .image-profile-modal {
        max-height: 340px;
    }   

    .btn-confirm{
        margin-right: 8px;
    }

    #close-modal-Add {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $pink;
        &:hover {
            color : $purple;
        }
        i {
            font-size: $fontLarge;
        }
    }
}

#modal-video .modal-content  {
    width: 35%;

    #close-modal-video {
        top: 4px;
        right: 1px;
        position: absolute;
        i {
            font-size: $fontHuge;
        }
    }
}

#modal-video {

    #close-modal-video {
        top: 7px;
        right: 20px;
        position: absolute;
        i {
            font-size: $fontDouble;
        }
    }

}

#modal-postulacion .modal-content {
    width: 50%;
    height: 200px;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

    #close-modal-postulacion {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $pink;
        &:hover {
            color : $purple;
        }
        i {
            font-size: $fontLarge;
        }
    }
}

#modal_pay_response .modal-content {
    width: 90%;
    margin: auto;
    background-color: $white;
    button { width: 100%; border-radius: 0 0 4px 4px; }
}

.modal-confirm {
    .btn-container-modal {
        button {
            width: 50%;
            padding: $paddingMedium;
            &:first-child { border-radius: 0 0 0 4px; }
            &:last-child { border-radius: 0 0 4px 0; }
        }
    }
}

@media screen and (max-width: 992px) {

    #modal-login .modal-content  {
        width: 70%;    

        p, form {
            width: 70%;
        }     

        #close-modal-login {
            position: absolute;
            top: 16px;
            right: 16px;
            i {
                font-size: $fontRegular;
            }
        }

    }

    #modal-Add .modal-content  {
        width: 55%;
        margin: auto;
        position: relative;
        align-self: flex-start;
        border-radius: 10px;
        background-color: $white;
    }

    #modal-activation .modal-content  {
        width: 40%;
        margin: auto;
        position: relative;
        align-self: flex-start;
        border-radius: 10px;
        background-color: $white;
    
    }
}

@media screen and (max-width: 768px) {

    /**     ---     ESTILOS BASE PARA MODALES       ---   **/

    .modal-content {
        width: 90%;
        background-color: $white;
        border-radius: 4px;        
    }

    .modal-alert, .modal-confirm {
        h3 {
            font-size: 1.25rem;
            padding: 0 $paddingNormal;
        }
        p {
            
            padding: 0 $paddingNormal;
        }
    }

    #modal-delete-account .modal-content {
        background-color: $white;
        width: 85%;
        margin: auto;
        position: relative;
        align-self: flex-start;
    
        #btn-deleteChat { 
            width: 50%; 
            padding: $paddingMedium;
            border-radius: 0 0 0 4px;
        }
    
        #close-modal-delete-account {
            width: 50%; 
            padding: $paddingMedium;
            border-radius: 0 0 4px 0;
        }
    }

    #modal-contact {
        .modal-content {
            width: 90%;
            p {
                width: 90%;
                text-align: left;
            }
            input, textarea {
                width: 90%;
            }
            button {
                width: 90%;
            }
        }
    }

    .modal-confirm {
        .btn-container-modal {
            button {
                width: 50%;
                padding: $paddingMedium;
                &:first-child { border-radius: 0 0 0 4px; }
                &:last-child { border-radius: 0 0 4px 0; }
            }
        }
    }

    /**     ---     FIN ESTILOS BASE PARA MODALES       ---   **/

    #modal-delePublication, #modal-deleInfluencerPublication {
        #btn-deletePublication {
            width: 60%;
            padding: $paddingMedium;
            border-radius: 0 0 0 4px;
            background-color: $pink;
            border: 0;
        }
        #btn-deletePublicationCancel {
            width: 40%;
            padding: $paddingMedium;
            border-radius: 0 0 4px 0;
            background-color: $red;
            border: 0;
        }
    }

    #modal-login .modal-content  {
        width: 80%;

        p, form {
            width: 70%;
        }     

        #close-modal-login {
            position: absolute;
            top: 16px;
            right: 16px;
            i {
                font-size: $fontRegular;
            }
        }
    }

    #modal-video .modal-content  {
        width: 90%;
    
        #close-modal-video {
            top: 4px;
            right: 1px;
            position: absolute;
            i {
                font-size: $fontMedium;
            }
        }
    }


    #modal-video {

        #close-modal-video {
            top: 5px;
            right: 10px;
            position: absolute;
            i {
                font-size: $fontMedium;
            }
        }

    }
    

    #modal-postulacion .modal-content {
        width: 80%;
    }
    
}

@media screen and (max-width: 576px) {
    #modal-login .modal-content  {
        width: 95%;
        background-position: left;
        p, form {
            width: 80%;
        }

    }
}
