/**
*   header.scss
*   @description: Estilos para los Headers
*/

#btn-menu {
	z-index: 1;
	background-color: transparent;
}

.header-sticky {
	position: sticky;
	top: 0;
	z-index: 1;
}
.menu-mobile {
	top: 0;
	left: 0;
	width: 60%;
	z-index: 1;
	height: 100vh;
	position: fixed;
	background-color: $darkBlue;
	transform: translateX(-100%);
	transition: all 200ms ease-in-out;
}

.menu-appear {
	transform: translateX(0);
}

.menu-hide {
	transform: translateX(-100%);
}

.menu-mobile .head {
	min-height: 160px;
	padding: 0 $paddingMedium;
	a {
		width: 100%;
	}
	.image img {
		width: 80px;
		height: 80px;
		max-height: 80px;
		object-fit: cover;
		border-radius: 50%;
	}
}

.menu-mobile .content {
	flex-grow: 1;
	padding: 0 $paddingMedium;
	background-color: $white;
	
	.menu-item {
		width: 100%;
		i {
			width: 11%;
			text-align: center;
		}
	}


}


.header-search {
	form { position: relative; }

	.icon-search {
		left: 18%;
		top: 12px;
		color: $gray;
		position: absolute;
	}

	.icon-search-search {
		top: 12px;
		font-size: 13px;
		left: 20%;
	}

   .burger-wrapper { cursor: pointer; }
  
  	#menu-filter {
		background: $white;
    	width: 25%;
    	height: 100vh;
    	position: fixed;
    	z-index: 2;
    	right: 0;
    	transition-timing-function: cubic-bezier(10, 2, 3, 1);
    	box-shadow: 6px -2px 20px 0px grey;
    	transform: translateX(50rem);
    	top: 0;
    	transition: 0.5s;

    	.input-container {
        	padding: 10px 0px 10px 0px;
        	h4 {
            	font-size: .93rem;
        	}
    	}

    	.header-filter {
    		background-color: $darkBlue;
			padding: 22px;
		}
		
		#followers {
			width: 100%;
		}
	}
  
  	#menyAvPaa {
		display: none;
		&:checked ~ #menu-filter {
			transform: translateX(0rem);
		}
	}

	.filter-icon {
		position: absolute;
		top: 21px;
		right: 17px;
	}

	#filter-text {
		margin-left: 2px;
	}
  
  	#filter {
		position: relative;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}
  
	#closemain{
		font-size: 25px;
	}
  
	#menyAvPaa:checked ~ #filter  {
		color: pink;
	}
}

.header-single-header-search {
	padding: 0;
}

/* --- HeaderChat ---	*/
.header-chat {
	background-color: $darkBlue;
	padding: $paddingNormal 0;
	height: 64px;
	.image {
		margin: 0 $paddingMedium;
		img {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}
	}

	.btn-purple { background-color: $purple; }

	
	.search-chat-icon {
		color: $white;
		margin-right: 0;
	}

	.description { 
		width: 55%;
		h5 { font-size: 0.71rem; font-weight: $weightBold; margin-top: 6px; }
		p { font-size: 0.65rem; }
	}

	.pay {
		width: 40%; 
		.btn {
			width: 100%;
			font-size: 0.7rem;
		}
	}

	.search { 
		height: 100%; 
		padding-bottom: 4px;
		padding-left: $paddingNormal;
	}
	
	.text-header {
		color: $white;
	}

	.btn{
		cursor: pointer;
		border-radius: 50px;
		font-family: "Open Sans", sans-serif;
		padding: 8px 16px;
		font-size: $fontTiny;
	}

	.back {
		display: block;
	}

	.space-icon {
		margin-right: 0px;
		top: 11px;
		left: 6px;
	}
}

/* HeaderWebLogin */

.header-web-login {
	background-color: #000000bd;
	padding: 14px 0;
	z-index: 1;
    position: fixed;
	top: 0;
	
	.btn-splash {
		padding-left: $paddingNormal;
	}

	.logo{
        height: 50px;
	}
	
	.logo-letter{
        height: 40px;
    }

    .icon-search-web {
        position: absolute;
        left: 3%;
        color: #b3b3b3;
    }
}

.header-web-interest{
	background-color: $darkBlue;
	padding: 16px 0;
	z-index: 1;
    position: relative;
	top: 0;
	
	.back-icon{
		padding-right: $paddingLarge;
	}
}

/* Header Loggeado */

.header-logged {
	background-color: $darkBlue;
	border-bottom: 3px solid $lightBlue;
	padding: 10px 0;
	z-index: 1;
    position: relative;
	top: 0;

	.btn-last {
		.btn-add {
		 	margin-right: $marginNormal;
		}
	}

	.btn-search {
		margin-right: $marginNormal;
		background-color: $darkBlue;
		border: 1px solid $lightBlue;
		transition: all 250ms ease-in-out;
		&:hover {
			color: $darkBlue;
			background-color: $lightBlue;
		}
	}

	.logo { 
		height: 40px;
	    position: absolute;
		left: 20px; 
	}

	.logo-search {
		height: 40px;
	    position: absolute;
		left: 20px; 
		top: 10px;
	}
	.icon-add { padding-right: $paddingNormal; }
	.icon-search-web {
		left: 3%;
		color: #b3b3b3;
        position: absolute;
    }
	
}

/* Header for mobile */
@media screen and (max-width: 768px) { 
	.header-single {
		height: 62px;
		background-color: $darkBlue;
		.logo img { height: 32px; }
		position: sticky;
		top: 0;
		z-index: 10;		
	
		.header-search {
			position: relative;
			padding: 0;
	
			 .icon-search {
				left: 55px;
			 }
	
			 .icon-search-search {
				left: 40px;
				font-size: $fontMini;
				color: $darkBlue;
			 }
			
			.back {
				left: 0;
				top: 22px;
				position: absolute;
			}
	
			.input-search {
				width: 75%;
			}
	
		}
	
		.search-button {
			left: 0;
			width: 100%;
			position: absolute;
			button {
				height: 36px;
				color: $darkBlue;
				padding: 4px 10px;
				border-radius: 4px;
				background-color: $white;
				i { font-size: 0.75rem; }
			}
		}
	
	}	
	
	/* Header search for mobile */
	
	.header-search {

		.back {
			width: 10%;
			z-index: 1;
		}
	
		.form-search {		
			left: 10%;
			width: 75%;	
			position: absolute;

		}

		
	
		.filter-icon {
			width: 15%;
			top: 16px;
			right: 0px;
			position: absolute;
		}
	
		.icon-search {
			left: 28px;
			color: $gray;
			position: absolute;
		}
	
	   .burger-wrapper { cursor: pointer; }
	  
		#filter-text {
			font-size: 0.8rem;
		}	
	
		  #menu-filter {
			background: $white;
			width: 80%;
			height: 100vh;
			position: fixed;
			z-index: 2;
			right: 0;
			transition-timing-function: cubic-bezier(10, 2, 3, 1);
			box-shadow: 6px -2px 20px 0px grey;
			transform: translateX(50rem);
			top: 0;
			transition: 0.5s;
	
			.input-container {
				padding: 10px 0px 10px 0px;
			 
				h4 {
					font-size: .93rem;
				}
			}		
	
			.header-filter {
				background-color: $darkBlue;
				padding: 16px;
			}
		}
	  
		  #menyAvPaa {
			display: none;
			&:checked ~ #menu-filter {
				transform: translateX(0rem);
			}
		}
	  
		  #filter {
			position: relative;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
		}
	  
		#closemain{
			font-size: 25px;
		}
	  
	  
		#menyAvPaa:checked ~ #filter  {
			color: white;
		}
	}
}