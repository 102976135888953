.home {
	.text-options-link {
		margin-left: 16px;
		text-decoration: underline;

		&:hover {
			color: $lightBlue;
			cursor: pointer;
		}
	}

	.list-videos {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 16px;
	}

	.list-cards {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 16px;
    }

	.scrollE {
		// max-height: 540px;
		max-height: 80vh;
		overflow: auto;
	}
}

@media screen and (max-width: 992px) {
	.home {
		.list-videos {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 16px;
		}
	}
}

@media screen and (max-width: 768px) {
	.home {
		.view-web {
			display: none;
		}

		.card-job {
			min-height: 144px;
			height: auto;
		}

		.card-video {
			width: 100%;
			min-height: 175px;
			position: relative;

			.contact {
				position: absolute;
				bottom: 0;
				width: 50%;
			}
			
		}

		.vc {
			height: 600px;
			overflow: auto;
		}
	}
}
