.offer-candidates {
    .cards-container {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 16px;
    }
    .header { 
        padding: $paddingMedium 0;
        background-color: $white; 
    }

    .card-company {
        .head {
            img { 
                width: 100%;
                height: 100%;
                min-height: 100%;
            }
        }
    }

    .contact-remove {
        margin-top: 10px;
        padding: $paddingNormal 0;
        background-color: rgba($red, 0.5);
    }
}

@media screen and (max-width: 992px) {
    .offer-candidates {
        .cards-container {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 16px;
        }
    }
}

@media screen and (max-width: 768px) {
    
    .offer-candidates {

        .view-web { display: none; }
        .header { 
            padding: $paddingMedium 0;
            background-color: $white; 
        }

        .card-company {
            .head {
                img { 
                    width: 100%;
                    height: 100%;
                    min-height: 100%;
                }
            }
        }

        .contact-remove {
            margin-top: 10px;
            padding: $paddingNormal 0;
            background-color: rgba($red, 0.5);
        }
    }

}