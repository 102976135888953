
    .badge-offer, .badge-offer-add {
        position: relative;
        border-radius: 16px;
        padding: 4px $paddingMedium;
        margin: $paddingNormal 4px;
        background-color: rgba($darkBlue, 0.1);
        &:first-of-type { margin-left: 0; }
        &:last-of-type { margin-right: 0; }
    }

    .badge-offer-add {
        padding-right: $paddingLarge;
        i { 
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }