
.upload-summary {

    background-color: $white;

    .content {
        height: 100%;
        flex-grow: 1;
    }

 

    .btn { width: 50%; }

    .upload-video {
        background-color: $white;
   
        .card-container {
            .btn { 
                width: 60%; 
                padding: $paddingNormal;
            }
        }

        .btn-container { width: 90%; }        

        .btn-bottom-light{ height: 50px; }

        video {
            width: 100%;
            height: auto;
        }

        .input { width: 100%; }

        .video-icon {
            border-radius: 50%;
            border: 2px solid;
            width: 150px;
            height: 150px;
            color: $darkBlue;
        }
   }

}


@media screen and (max-width: 778px) {
    .upload-summary {

        background-color: #223053;
        min-height: 100vh;
    
        .content {
            height: 100%;
            flex-grow: 1;
        }
    
        .divider {
            width: 80%;
            height: 1px;
            background-color: $darkBlue;
        }
    
        .btn { width: 100%; }
    
        .upload-video {
            background-color: #223053;
       
            .card-container {
                .btn { 
                    width: 60%; 
                    padding: $paddingMedium;
                }
            }
    
            .btn-container { width: 90%; }        
    
            .btn-bottom-light{ height: 50px; }
    
            video {
                width: 100%;
                height: auto;
            }
    
            .input { width: 100%; }
    
            .video-icon {
                border-radius: 50%;
                border: 2px solid;
                width: 150px;
                height: 150px;
                color: $darkBlue;
            }
       }
    
    }
}