/**
*   colors.scss
*   @description: Variables de colores
*/


$black:             #000000;
$blackOverlay:      rgba(0, 0, 0, 0.6);
$blackOverlayWeb:    rgba(0, 0, 0, 0.205);
$white:             #FFFFFF;
$pink:              #FF0357;
$purple:            #7633fd;
$bgGray:            #f1f1f1;
$softGray:          #f7f7f7;
$red:               #CF000F;
$gray:              #b3b3b3;
$darkGray:          #8f8f8f;
$dimGray:           #7d7d7d;
$lightGray:         #d8d8d8;
$darkBlue:          #0C1B42;
$lightBlue:         #09ffcc;
$OverlayLightBlue:  #09ffcec4;
/* Classes de colores */

.color-white {
    color: $white;
}

.color-black {
    color: $black;
}

.color-darkBlue {
    color: $darkBlue;
}

.color-lightBlue {
    color: $lightBlue;
}


.color-gray {
    color: $gray;
}

.color-darkgray {
    color: $darkGray;
}

.color-dimgray {
    color: $dimGray;
}

.color-red {
    color: $red;
}