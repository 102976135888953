/**
*   inputs.scss
*   @description: Estilos para las entradas de texto y archivos
*/

input, textarea, select, option, label {
    font-family: $OpenSans;
}

textarea {
    resize: none;
}

.input {
    font-size: $fontSmall;
    font-family: $OpenSans;
    font-weight: $weightSemi;
    outline: 1px solid transparent;
    background-color: #EFEFEF;
    padding: $paddingNormal $paddingMedium;
}

.input::placeholder, option, select {
    color: #8e8e8e;
}

.profile select {
    color: $black;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px #EFEFEF inset !important;
}

.input-search {
    width: 90%;
    margin-left: 0;
    border-radius: 24px;
    font-size: $fontSmall;
    border: 1px solid #ccc;
    background-color: $white;
    padding: 3px 3px 6px 40px;
    background-repeat: no-repeat;
    background-position: 10px 10px;
}

.input-filter {
    background: $white;
    width: 120px;
}

.input-filter-opt {
    width: 170px;
    background-color: $white;
}

.input-profile {
    width: 85%;
    margin-left: 14px;
    border-bottom: .5px solid $gray;
    background-color: white;
    padding: 8px 8px 8px 0px;
    font-size: $fontMini;
    transition: border-bottom .300s ease-in-out;
}

.input-profile-noline {
    width: 85%;
    margin-left: 14px;
    background-color: white;
    padding: 8px 8px 8px 0px;
    font-size: $fontMini;
    transition: border-bottom .300s ease-in-out;
}

  .input-profile-noline:focus, .input-profile:focus {
    border-bottom: .5px solid $darkBlue;
  }

  .input-password {
    width: 95%;
    background-color: white;
    padding: 8px 8px 8px 8px;
    font-size: $fontMini;
    border: 1px solid $gray;
    border-radius: 5px ;
    transition: border .300s ease-in-out;
}


.input-password:focus {
    border: 1px solid $lightBlue;
}


.input-search-web {
    width: 100%;
    border-radius: 4px;
    font-size: $fontTiny;
    font-weight: $weightSemi;
    border: 1px solid #ccc;
    background-color: #FFFFFF;
    padding: 7px 7px 7px 30px;
}

.input-login {
    width: 100%;
    background-color: white;
    font-size: 0.8rem;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    transition: border .300s ease-in-out;
}

@media screen and (max-width: 768px) {
    .offer-add {
        .input-offer, .input-select {
            font-size: 1rem;
            padding: 4px $paddingNormal;
            background-color: $lightGray;
            &::placeholder { font-weight: $weightSemi; }
            ::-webkit-input-placeholder { font-weight: $weightSemi; }
        }
        .input-offer-title {
            font-size: 1.6rem;
            font-weight: $weightBold;
        }
    }
}