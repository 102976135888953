/**
*   interest.scss
*   @description: Estilos de la página de intereses
*/
.interests {
    background: white;
    min-height: 100vh;

    .interests-options {        
        display: grid;
        grid-template-columns: repeat(6,1fr);
        grid-gap: 16px;

        .photo {
            width: 100%;
            height: 185px;
            position: relative;
            cursor: pointer;
        }

        .img-gallery {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        
        .tittle {
            top: 0;
            height: 100%;
            position: absolute;
            border-radius: 10px;
            background: #13131370;
            &:hover { background: #08ebbead; }
            h4 {
                padding: 0 $paddingMedium;
            }
        }

        .active-option {
            .tittle { background: #08ebbead; }
            &::before {
                content: "\f058";
                font-family:"Font Awesome 5 Free";
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit; 
                color: $white;
                border: 2px solid $darkBlue;
                background-color: $darkBlue;
                border-radius: 50%;
                font-size: $fontLarge;
                font-weight: $weightBold;
                position: absolute;
                top: 5px;
                right: 5px;
                z-index: 1;
            }
        }
    }

    .interests-options {        
        display: grid;
        grid-template-columns: repeat(6,1fr);
        grid-gap: 16px;

        .photo {
            width: 100%;
            height: 175px;
            position: relative;
            cursor: pointer;
        }
    }

    .interests-container {
        max-height: 500px;
        padding-bottom: 48px;
        overflow-y: scroll;
    }

    .interests-element {
        width: 100%;
        border-radius: 5px;
        margin-bottom: 4px;
        padding: $paddingLarge 0;
        border: 1px solid $gray;
        p {
            padding: 0 2px;
        }
        &.active{
            color: black;
            background-color: $lightBlue;
        }
    }        


    .abilities-options {
        height: 100vh;
        width: 60%;
        position: sticky;
        top: 0;
        padding-left: $paddingSemi;

        .skill-list {
            overflow-y: auto;
            max-height: 120px;
        }

        .btn { width: 100%; }
    }

    .conteiner-labels {

        .row-labels{ justify-content: center; }

        .user-label {
            background: $lightBlue;
            padding: 4px;
            margin: 4px 0;
            border-radius: 15px;
            margin-right: 5px;
            margin-bottom: 5px;
            h5 { 
                padding: 2px 4px;
                font-size: 0.75rem;
            }
        }
        .active-label {
            background-color: $darkBlue;
            h5 { color: $white; }
        }
    }  

}

@media screen and (max-width: 992px){
    .interests {
        .interests-options {        
            display: grid;
            grid-template-columns: repeat(4,1fr);
            grid-gap: 16px;

            .photo {
                width: 100%;
                height: 175px;
                position: relative;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 768px) {
 
    .interests {
        h2 { font-size: $fontMedium; }

        .view-web { display: none; }

        .interests-container {
            max-height: 500px;
            padding-bottom: 48px;
            overflow-y: scroll;
        }

        .interests-element {
            width: 48.6%;
            border-radius: 5px;
            margin: $marginNormal 0;
            margin-bottom: 4px;
            padding: $paddingMedium 0;
            border: 1px solid $gray;
            p {
                padding: 0 2px;
            }
            &.active{
                color: black;
                background-color: $lightBlue;
            }
        }        
        
        #btn-top {
            left: 0;
            bottom: 0;
            background-color: $white;
            position: fixed;
            .btn { 
                width: 100%; 
                padding: $paddingMedium;
            }
        }

    }

}