/**
*   abilities.scss
*   @description: Estilos de la página de habilidades
*/

.abilities {
    h2 { font-size: $fontRegular; }

    .interests-container {
        max-height: 410px;
        overflow-y: scroll;
    }

    .interests-element {
        width: 48%;
        border-radius: 5px;
        margin: $marginNormal 0;
        padding: $paddingMedium 0;
        border: 1px solid $gray;
        &:hover {
            color: $darkBlue;
            background-color: $lightBlue;
            border: 1px solid $lightBlue;
            font-weight: $weightSemi;
        }
        
    }        

    select, textarea { border-radius: 4px; }

    .interests-options {        
        display: grid;
        grid-template-columns: repeat(5,1fr);
        grid-gap: 16px;

        .photo {
            width: 100%;
            height: 140px;
            position: relative;
            cursor: pointer;
        }

        .img-gallery {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        
        .tittle {
            top: 0;
            height: 100%;
            position: absolute;
            border-radius: 10px;
            background: #13131370;
            &:hover { background: #08ebbead; }
            h4 {
                padding: 0 $paddingMedium;
            }
        }

        .active-option {
            .tittle { background: #08ebbead; }
            &::before {
                content: "\f058";
                font-family:"Font Awesome 5 Free";
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit; 
                color: $white;
                border: 2px solid $darkBlue;
                background-color: $darkBlue;
                border-radius: 50%;
                font-size: $fontLarge;
                font-weight: $weightBold;
                position: absolute;
                top: 5px;
                right: 5px;
                z-index: 1;
            }
        }
    }

    .column-qualities {
        width: 50%;
        margin-left: $marginLarge;
    }
    
    .skill-list {
        height: 300px;
        overflow-y: scroll;
        border-bottom: 1px solid $lightGray;
    }
    
    .divider {
        padding: 8px 0;
        border-bottom: 1px solid $lightGray;
    }

    .conteiner-labels {
        padding-right: 16px;
        margin-right: 26px;
        overflow-y: scroll;
        max-height: 600px;
    
        .row-labels{ justify-content: center; }

        .user-label {
            background: $lightBlue;
            width: 100%;
            height: 140px;
            padding: 4px;
            margin: 4px 0;
            border-radius: 15px;
           
            h5 { 
                padding: 4px;
                font-size: 0.75rem;
            }
        }
        .active-label {
            background-color: $darkBlue;
            h5 { color: $white; }
        }
    }
    
    #btn-top {
        left: 0;
        bottom: 0;
        position: fixed;
        .btn { 
            width: 100%; 
            padding: $paddingMedium;
        }
    }

}

@media screen and (max-width: 768px) {
 
    .abilities {
        h2 { font-size: $fontRegular; }

        .view-web { display: none}

        .interests-container {
            max-height: 410px;
            overflow-y: scroll;
        }

        .interests-element {
            width: 48%;
            border-radius: 5px;
            margin: $marginNormal 0;
            padding: $paddingMedium 0;
            border: 1px solid $gray;
            &:hover {
                color: $darkBlue;
                background-color: $lightBlue;
                border: 1px solid $lightBlue;
                font-weight: $weightSemi;
            }
            
        }        

        select, textarea { border-radius: 4px; }

        .skill-list {
            height: 240px;
            overflow-y: scroll;
            border-bottom: 1px solid $lightGray;
        }
        
        .divider {
            padding: 8px 0;
            border-bottom: 1px solid $lightGray;
        }

        .conteiner-labels {
            .row-labels{ justify-content: center; }

            .user-label {
                background: $lightBlue;
                padding: 4px;
                width: auto;
                height: auto; 
                margin: 4px 0;
                border-radius: 15px;
                margin-right: 5px;
                margin-bottom: 5px;
                h5 { 
                    padding: 4px;
                    font-size: 0.75rem;
                }
            }
            .active-label {
                background-color: $darkBlue;
                h5 { color: $white; }
            }
        }
        
        #btn-top {
            left: 0;
            bottom: 0;
            position: fixed;
            .btn { 
                width: 100%; 
                padding: $paddingMedium;
            }
        }

    }

}