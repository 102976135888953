
.splash {

    height: auto;
    

    .content-splash {
        p, h1, h2 , h3 , h4 {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 300;
            letter-spacing: 2px;
        }
    }

    .background {
        @include backgroundImage;
        background-size: cover;
        height: 100vh;
        width: 100%;
    }

    .content-data {
        background-color: $white;
        height: 750px;

        position: relative;

        .white-space-24 {
            height: 24px;
        }

        .white-space-32 {
            height: 80px;
        }

        .white-space-16 {
            height: 16px;
            &:first-child {
                height: 16px;
            }
        }

        .footer {
            bottom: 0;
            position: absolute;
            height: 50px;
            padding: $paddingMedium;
            background: $black;
    
            .social-media {
                width: 10%;
            }
    
            .legal {
                width: 40%;
            }
    
            .logo {
                .logo-footer{
                    height: 55px;
                }
            }
        }
    }

    .overlay {
        background-color: $blackOverlayWeb;
        height: 100vh;
    }
    
    

    .logo img { max-height: 200px; }

    .flickity-prev-next-button { display: none; }

    .flickity-page-dots .dot {
        width: 8px;
        height: 8px;
        opacity: 1;
        background: transparent;
        border: 2px solid white;
    }
        
    .flickity-page-dots .dot.is-selected { background: white; }    

    //desktop

        .container-preview {
            width: 85%;
        }
            
        .card-info-brands {
            width: 35%;
            padding: 24px;
            background:#0c1b42eb;
            max-height: 80px;
            border-radius: 0;
            overflow: hidden;
            bottom: 30px;
            position: absolute;
            right: 50%;

            p, h3 {
                font-family: $OpenSans;                
                letter-spacing: 0;
            }

            -webkit-transition: max-height .9s;
            -moz-transition: max-height .9s;
            transition: max-height .9s;
            

            .card-title {
            
                margin-bottom: 24px;
                text-decoration: none;
                font-family: $OpenSans;
                font-weight: $weightSemi;
                position: relative;            
            
                
                -webkit-transition: all .50s ease-out;
                -moz-transition: all .50s ease-out;
                -o-transition: all .50s ease-out;
                -ms-transition: all .50s ease-out;
                transition: all .50s ease-out;
               
            
            
                &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: $white;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all .5s cubic-bezier(1,.25,0,.75) 0s;;
                transition: all .5s cubic-bezier(1,.25,0,.75) 0s;;
                }
               
        
            }
            
            
            &:hover {
                max-height: 500px;
                border-radius: 8px 8px 0 0;

                .card-title:before {                  
                        transition-delay: .5s;  
                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                }

            }
           
        }

        .card-info-candidates {
            width: 35%;
            padding: 24px;
            background: #fffffff0;
            max-height: 80px;
            border-radius: 0;
            overflow: hidden;
            bottom: 30px;
            position: absolute;
            left: 50%;

            p, h3 {
                font-family: $OpenSans;                
                letter-spacing: 0;
            }

            

            -webkit-transition: max-height .9s;
            -moz-transition: max-height .9s;
            transition: max-height .9s;

            .card-title {
            
                margin-bottom: 24px;
                text-decoration: none;
                position: relative;       
                font-family: $OpenSans;     
                font-weight: $weightSemi;
                
                -webkit-transition: all .50s ease-out;
                -moz-transition: all .50s ease-out;
                -o-transition: all .50s ease-out;
                -ms-transition: all .50s ease-out;
                transition: all .50s ease-out;
               
            
            
                &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: $darkBlue;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all .5s cubic-bezier(1,.25,0,.75) 0s;;
                transition: all .5s cubic-bezier(1,.25,0,.75) 0s;;
                }
               
        
            }
            
            
            &:hover {
                max-height: 500px;
                border-radius: 8px 8px 0 0;

                .card-title:before {                  
                        transition-delay: .5s;  
                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                }

            }
        }

        .more { 
            bottom: 0;
            position: absolute;
            padding: $paddingNormal;
            background-color: $lightBlue;
        }


        .interests-options {        
            display: grid;
            grid-template-columns: repeat(4,1fr);
            grid-gap: 32px;
    
            .photo {
                width: 100%;
                height: 185px;
                position: relative;
                cursor: pointer;
            }
    
            .img-gallery {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
            
            .tittle {
                top: 0;
                height: 100%;
                position: absolute;
                border-radius: 10px;
                background: #13131370;
               
            }
    
            
        }

       
    

       

  
}

.activate {
    .logo-img{
        width: 15%;
    }

    .tittle-img {
        width: 35%;
    }

    .top-space {
        height: 0;
    }
}

@media screen and (min-width: 1440px) { 
    .splash { 

        .content-data {
            height: 760px;

            .white-space-24 {
                height: 32px;
            }

            .white-space-32 {
                height: 85px;
            }

            .white-space-16 {
                height: 24px;
                &:first-child {
                    height: 40px;
                }
            }

            h4 {
                margin-bottom: $marginNormal;
            }

            .footer  {

                h4 {
                    margin-bottom: 0;
                }

            }

        }

        
    }

}

@media screen and (max-width: 992px) {
    
.splash {

    .card-info-brands {
        width: 45%;
    }

    .card-info-candidates { 
        width: 45%;
    }
    .content-data {
        background-color: $white;
        height: 120vh;

        .white-space-64 {
            height: 24px;
        }

        position: relative;

        .interests-options {        
            display: grid;
            grid-template-columns: repeat(4,1fr);
            grid-gap: 8px;
        }

        .footer {
            bottom: 0;
            position: absolute;
            height: 50px;
            padding: $paddingMedium;
            background: $black;
    
            .social-media {
                width: 10%;
            }
    
            .legal {
                width: 40%;
            }
    
            .logo {
                .logo-footer{
                    height: 55px;
                }
            }
        }
    }
}
    
}

@media screen and (max-width: 768px) {

    .splash {
        max-height: 100vh;

        .view-web { display: none; }

        .overlay {
            height: 100vh; 
            background-color: $blackOverlay;
        }

        .slider .content { width: 100%; }

        flickity-prev-next-button { display: none; }
    
        .flickity-page-dots .dot {
            width: 8px;
            height: 8px;
            opacity: 1;
            background: transparent;
            border: 2px solid white;
        }
            
        .flickity-page-dots .dot.is-selected { background: white; }

        .isotipo {
            img { max-height: 100px; }
        }

        .logo img { max-height: 50px; }

        .btn-containers {
            left: 0;
            bottom: 0px;
            position: absolute;
        }

    }

    .activate {
        @include backgroundImage;

        .content {
            justify-content: start;
        }
        .logo-img{
            width: auto;
        }
    
        .tittle-img {
            width: auto;
        }
    
        .top-space {
            height: 64px;
        }
        .btn {
            width: 80%;
        }
    }

}