::-webkit-scrollbar { width: 8px; }

::-webkit-scrollbar-thumb { background-color: $darkGray; }

.scrollE::-webkit-scrollbar { width: 4px; }

.scrollE::-webkit-scrollbar-thumb { background-color: $lightGray; }

@media screen and (max-width: 768px) {

    ::-webkit-scrollbar { width: 0; }
    
    ::-webkit-scrollbar-thumb { background-color: transparent; }
}