
.find {
    .icon-search {
        top: 12px;
        font-size: 13px;
        left: 3%;
    }
    .list-videos {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 16px;
    }
}
@media screen and (max-width: 992px) {
    .find{
        .list-videos {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 16px;
        }
    }
}

@media screen and (max-width: 768px) {

    .search {

        background-color: $darkBlue;
        .taps-column {
            width: 50%;
            padding: 12px 0;
        }
    
        .tap {
            font-size: $fontMini;
            background-color: $darkBlue;
        }
    
        .tap-active {
            border-bottom: 2px solid $lightBlue;
        }
    }

    .find {
        .view-web { display: none; }

        

        #menu-filter {
            .input-filter {
                font-size: $fontMini;
            }

            .input-container {
                h4 {
                    font-size: $fontMini;
                }
            }
            .input-filter-opt {
                font-size: $fontMini;
            }

        }
       

        .vc {
            height: 500px;
            overflow: auto;
        }
    }

    .cards-container {
        overflow-y: scroll;
    }

}