/**
*   tabs.scss
*   @description: Estilos para las tabs
*/

.tabs-menu-profile {
    background-color: $darkBlue;
    padding: $paddingNormal 0;
    padding-bottom: 0;
    box-shadow: 0 0px 1px 0 rgba(0,0,0,0.10);
    .active-tab { border-bottom: 4px solid $lightBlue; }
}

.tabs-menu {
    background-color: $darkBlue;
    padding: $paddingNormal 0;
    padding-bottom: 0;
    box-shadow: 0 0px 1px 0 rgba(0,0,0,0.10);
    .title { margin: 4px 0; }
    i, a { color: $white; }
    button { background-color: transparent; }
    .tab-active { border-bottom: 4px solid $lightBlue; }

    .videos, .jobs, .companies {
        button {
            color: $white;
        }
    }
}


@media screen and (max-width: 768px) {

    .tabs-menu {
        background-color: $white;
        padding: $paddingNormal 0;
        padding-bottom: 0;
        box-shadow: 0 0px 1px 0 rgba(0,0,0,0.10);
        .title { margin: 4px 0; }
        .tab-active { border-bottom: 1px solid $lightBlue; }
        i,a {
            color: $darkBlue 
        }

        .videos, .jobs, .companies {
            button {
                color: $darkBlue;
            }
        }
    }

}