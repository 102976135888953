.favorites {
    
    .list-videos {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 16px;
    }

    .list-cards {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 16px;
        
    }
    .scrollE {
        max-height: 540px;
        overflow: auto;
    }
}

@media screen and (max-width: 992px) {
    .favorites {
        .list-videos {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 16px;
        }
    }
}

@media screen and (max-width: 768px) {

    .favorites {
        
        .view-web { display: none; }

        .header {
            background-color: $white; 
            h4 {
                padding: $paddingMedium;
            }
        }

        .card-video {
            height: 131px;
        }

        .vc {
            height: 420px;
            overflow: auto;
        }
        

    }

}